<template>
  <transition :name="transitionName">
    <div class="moder-review-wrapper" :class="{ 'moder-review-collapsed': collapsed }">
      <div class="moder-review-collapse-info justify-content-between" v-if="collapsed">
        <div class="moder-review-collapse-info-left-part">
          <div class="moder-review-collapse-info-column">
            <span>
              {{
                reviewsOptions.groups.ACCEPTED_STATUSES.includes(review.moderation_status)
                  ? 'Одобрен'
                  : 'Отклонен'
              }}
              {{ review.processed_by }}
              {{ review.processed_at | formatDateTime }}

              <template
                v-if="!reviewsOptions.groups.ACCEPTED_STATUSES.includes(review.moderation_status)"
              >
                по причине "{{
                  refuseReasons
                    .filter((val) => review.refuse_reasons.includes(val.id))
                    .map((val) => {
                      return val.title;
                    })
                    .join(', ')
                }}"
              </template>
            </span>
            <span>#{{ review.id }}</span>
          </div>
          <div class="moder-review-collapse-info-column">
            <span>Исполнитель</span>
            <a class="link" :href="'//www.instagram.com/' + review.advertiser">{{
              review.advertiser
            }}</a>
          </div>
          <div class="moder-review-collapse-info-column">
            <span>Заказчик</span>
            <a class="link" :href="'//www.instagram.com/' + review.customer">{{
              review.customer
            }}</a>
          </div>
        </div>
        <div class="moder-review-collapse-info-right-part d-flex">
          <div class="moder-review-collapse-info-column">
            <span
              class="link moder-review-collapse-control position-relative d-flex align-items-center"
              :class="{ active: collapseOpen }"
              @click="toggleCollapse()"
            >
              {{ collapseOpen ? 'Скрыть отзыв' : 'Открыть отзыв' }}
            </span>
          </div>
        </div>
      </div>
      <b-collapse v-model="collapseOpen" :visible="!collapsed" class="moder-review">
        <div class="moder-review-shit"></div>
        <div class="moder-review-who">
          {{ review.created_at | formatDateTime }}<span>, пользователь</span> №{{ review.user }}
        </div>
        <div class="moder-review-left">
          <div class="moder-review-left-top">
            <div class="moder-review-left-top-me">
              <div class="moder-review-left-top-me-pre text">Мой аккаунт</div>
              <div class="moder-review-left-top-me-content link my-account-link">
                <a ref="adv" :href="'//www.instagram.com/' + review.customer" target="_blank">
                  {{ review.customer }}
                </a>
              </div>
              <div class="swap-btn" @click="swap()">
                <b-icon-arrow-down-up style="width: 25px; height: 25px" />
              </div>
              <div class="moder-review-left-top-me-pre text">Кто рекламировал</div>
              <div class="moder-review-left-top-me-content link mb-2">
                <a ref="cus" :href="'//www.instagram.com/' + review.advertiser" target="_blank">
                  {{ review.advertiser }}
                </a>
              </div>
              <div class="mb-2">
                <b-button @click="openLinks" variant="link">Открыть оба аккаунта</b-button>
              </div>
              <div class="moder-review-left-top-me-content mb-2">
                Тип заказчика:
                <redactable-string
                  datatype="select"
                  :width="160"
                  v-model="review.customer_kind"
                  :options="[
                    { text: 'Коммерция', value: 'shop' },
                    { text: 'Блоггер', value: 'blogger' },
                  ]"
                />
              </div>
              <div style="font-size: 16px !important" class="d-flex">
                <div class="moder-review-left-top-me-more-mark mt-2">
                  <div class="text textone">Оценка: {{ review.rate }}</div>
                  &nbsp;<i class="custom-star">&#xe800;</i>
                </div>
                <div class="text mt-2" v-if="review.customer_kind === 'shop'">
                  Окупилась:
                  <redactable-string
                    datatype="select"
                    :width="70"
                    v-model="review.paid_off"
                    :options="[
                      { text: 'Да', value: true },
                      { text: 'Нет', value: false },
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
          <review-summary v-if="isMobile" :review="review" />
          <div class="moder-review-left-comment" v-if="review.text_display">
            <div class="moder-review-left-comment-menu">
              <div class="moder-review-left-comment-menu-visible" id="delRec">Текст отзыва</div>
              <div class="link" id="del1" v-if="review.show_text" @click="review.show_text = false">
                Удалить текст отзыва
              </div>
              <div class="link" id="del1" v-else @click="review.show_text = true">
                Показать текст отзыва
              </div>
            </div>
            <div class="moder-review-left-comment-main text" id="del11" v-show="review.show_text">
              {{ review.text_display }}
            </div>
          </div>
          <div class="moder-review-left-about">
            <div class="moder-review-left-about-item text">
              <span class="field-label">Приход:&nbsp;</span>
              <redactable-string :width="100" datatype="number" v-model="review.arrival" />
            </div>
            <div class="moder-review-left-about-item text">
              <span class="field-label">Формат:&nbsp;</span> {{ review.ad_type_display }}
            </div>
            <div class="moder-review-left-about-item text" v-if="review.customer_kind === 'shop'">
              <span class="field-label">Товар или услуга:&nbsp;</span>
              <redactable-string :width="150" datatype="string" v-model="review.item" />
            </div>
            <div class="moder-review-left-about-item text">
              <span class="field-label">Дата выхода рекламы:&nbsp;</span>
              {{ review.date | formatDateTime('DD.MM.YY') }}
            </div>
            <div class="moder-review-left-about-item text">
              <span class="field-label">Цена рекламы:&nbsp;</span>
              <redactable-string
                datatype="select"
                v-model="review.price_meta"
                :options="[
                  { text: 'ВП', value: 'together' },
                  { text: 'Бартер', value: 'barter' },
                ]"
              />
              <span v-if="review.price_meta && review.price"> с доплатой </span>
              <redactable-string datatype="number" v-model="review.price" />₽
            </div>
            <div class="moder-review-left-about-item text">
              <span class="field-label">Наибольший охват:&nbsp;</span>
              <redactable-string datatype="number" v-model="review.largest_reach" />
            </div>
            <div class="moder-review-left-about-item text" v-if="review.clicks_count">
              <span class="field-label">Клики по рекламе:&nbsp;</span>
              <b-form-input v-model="review.clicks_count"></b-form-input>
            </div>
            <div class="moder-review-left-about-item text" v-if="review.customer_tags.length">
              <span class="field-label">Тема аккаунта заказчика:&nbsp;</span>
              {{ getTagsString() }}
            </div>
            <div class="moder-review-left-about-item" v-if="review.screenshots.length">
              <a
                class="link"
                target="_blank"
                :href="'/reviews/moderation/' + review.id + '/screenshots/'"
                >Скриншоты переходов</a
              >
              <div class="moder-review-left-top-screens-container">
                <div
                  class="moder-review-left-top-screens-item"
                  :key="screenshot.id"
                  v-for="(screenshot, num) in review.screenshots"
                  @click="showScreenshot(num)"
                >
                  {{ num + 1 }}
                </div>
              </div>
            </div>
          </div>
          <div class="moder-review-left-bottom" v-if="!hideControls">
            <div class="moder-review-left-bottom-buttons">
              <div
                class="moder-review-left-bottom-button clickNo"
                @click="openSolutionPopup('cancel')"
              >
                <b-icon style="width: 30px; height: 30px" icon="x" variant="danger" />
              </div>
              <div class="moder-review-left-bottom-button" @click="accept()">
                <b-icon style="width: 25px; height: 25px" icon="heart-fill" variant="success" />
              </div>
              <div
                class="moder-review-left-bottom-button clickOther"
                @click="openSolutionPopup('other')"
              >
                <div class="text">Другое</div>
              </div>
            </div>
            <p class="moder-review-left-bottom-right text">#{{ review.id }}</p>
          </div>
        </div>
        <review-summary v-if="!isMobile" :review="review" />
      </b-collapse>

      <b-modal
        size="lg"
        v-model="showScreenshots"
        hide-footer
        scrollable
        :title="`Скриншот ${currentSlide + 1}`"
      >
        <b-row>
          <b-col>
            <b-carousel
              v-model="currentSlide"
              controls
              :interval="0"
              indicators
              :ride="false"
              background="#ababab"
              style="text-shadow: 1px 1px 2px #333"
            >
              <b-carousel-slide v-for="screen in review.screenshots" :key="screen.id">
                <template #img>
                  <img
                    class="d-block img-fluid m-auto"
                    width="540"
                    :src="screen.image"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import ReviewSummary from './ModerReviewSummary.vue';
import moderApi from '../../api/reviews-moderation';
import EventBus from '../../event-bus';
import RedactableString from '../reusable/elements/inputs/RedactableString.vue';

export default {
  props: ['review', 'collapsed', 'hideControls'],
  components: {
    ReviewSummary,
    RedactableString,
  },
  computed: {
    ...mapState('reviewsModeration', ['refuseReasons']),
    ...mapState(['tags', 'reviewsOptions']),
  },
  data: () => ({
    isMobile,
    transitionName: 'bounceDown',
    collapseOpen: false,
    currentSlide: 0,
    showScreenshots: false,
  }),
  watch: {
    'review.customer_kind': {
      handler(val) {
        if (this.review.price_meta) {
          if (val === 'blogger') {
            this.review.price_meta = 'together';
          } else if (val === 'shop') {
            this.review.price_meta = 'barter';
          }
        }
      },
    },
  },
  methods: {
    ...mapMutations('reviewsModeration', [
      'showSolutionPopup',
      'setSolutionPopupMode',
      'setCurrentSolutionReview',
    ]),
    openLinks() {
      console.log(this.$refs);
      this.$refs.adv.click();
      setTimeout(() => {
        this.$refs.cus.click();
      });
    },
    swap() {
      const temp = this.review.advertiser;
      this.review.advertiser = this.review.customer;
      this.review.customer = temp;
    },
    getTagsString() {
      return this.review.customer_tags.map((x) => x.name).join(', ');
    },
    accept() {
      let status;
      if (this.review.moderation_status === 6) {
        status = 4;
      } else if (this.review.moderation_status === 7) {
        status = 3;
      } else {
        status = 2;
      }
      this.transitionName = 'fadeRight';
      moderApi.update({
        ...this.review,
        id: this.review.id,
        moderation_status: status,
        banned: false,
        show_text: this.review.show_text,
        largest_reach: this.review.largest_reach,
      });
      this.$nextTick(() => this.$emit('processed'));
    },
    toggleCollapse() {
      this.transitionName = '';
      this.$nextTick(() => {
        this.collapseOpen = !this.collapseOpen;
      });
    },
    openSolutionPopup(mode) {
      this.showSolutionPopup();
      this.setSolutionPopupMode(mode);
      this.setCurrentSolutionReview(this.review);
    },
    showScreenshot(i) {
      this.currentSlide = i;
      this.showScreenshots = true;
    },
  },
  mounted() {
    if (!this.collapsed) {
      this.collapseOpen = true;
    }
    EventBus.$on(`processed-review-${this.review.id}`, (accepted) => {
      this.transitionName = accepted ? 'fadeRight' : 'fadeLeft';
      this.$nextTick(() => this.$emit('processed'));
    });
  },
};
</script>

<style>
.moder-review-screenshot {
  max-width: 65%;
}
.carousel-control-prev,
.carousel-control-next {
  color: #000;
}
</style>

<style scoped>
.field-label {
  color: #6a7b83;
}

::-webkit-scrollbar-button {
  background: #ffffff none no-repeat;
  width: 5px;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  width: 7px;
  background-color: #c1c1c1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #56999f;
}

::-webkit-resizer {
  background: #ffffff none no-repeat;
  width: 7px;
  height: 0;
}

::-webkit-scrollbar {
  width: 7px;
}

.moder-review-wrapper:not(.moder-review-collapsed) {
  margin-bottom: 32px;
}

.moder-review-collapsed .moder-review {
  border-radius: 0;
  border-bottom: 2px solid #d2dce0;
}

.moder-review-collapse-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  border-bottom: 1px solid #d5dfe4;
}

.moder-review-collapse-info-left-part {
  justify-content: space-between;
  width: calc(50% - 10px);
  display: flex;
}

.moder-review-collapse-info-column {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  font-size: 14px;
}

.moder-review-collapse-control::before {
  content: '';
  border: 5px solid transparent;
  border-top: 5px solid #6a7b83;
  position: absolute;
  right: 7px;
  top: 11px;
}

.moder-review-collapse-control.active:before {
  transform: rotate(180deg) translateY(50%);
  top: 9px;
}

.moder-review-collapse-control::after {
  content: '';
  width: 24px;
  height: 24px;
  border: 1px solid #d5dfe4;
  display: block;
  border-radius: 5px;
  margin-left: 8px;
}

@media (min-width: 768px) {
  .moder-review:not(:last-child) {
    margin-bottom: 40px;
  }

  .moder-review {
    border-radius: 15px;
    -webkit-box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.04);
    -moz-box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.04);
    box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.04);
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }

  .moder-review-who {
    position: absolute;
    font-size: 16px;
    color: #6a7b83;
    top: 16px;
    right: 18px;
  }

  .moder-review-left {
    margin-top: 25px;
    margin-left: 20px;
    width: 520px;
  }

  .moder-review-left-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 99%;
    margin-top: -8px;
  }

  .moder-review-left-top-me-pre {
    margin-bottom: 5px;
  }

  .moder-review-left-top-me-content {
    font-size: 20px;
  }

  .moder-review-left-top-me-content {
    margin-bottom: 24px;
    cursor: pointer;
  }

  .moder-review-left-top-me-more {
    width: 230px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .moder-review-left-top-me-more-mark {
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .moder-review-left-top-me-more .text {
    font-size: 16px;
    font-family: Rubik;
    font-weight: 400 !important;
  }

  .moder-review-left-top-me-more-mark img {
    width: 15px;
  }

  .moder-review-left-top-screens {
    width: 265px;
  }

  .moder-review-left-top-screens .link {
    font-size: 16px;
    margin-top: -3px;
    margin-bottom: 0;
  }

  .moder-review-left-top-screens-container {
    width: 265px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .moder-review-left-top-screens-item {
    margin-top: 4px;
    margin-bottom: 10px;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5dfe4;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }

  .moder-review-left-comment-main {
    line-height: 20px;
    letter-spacing: 0.00000001em;
  }

  .moder-review-left-comment-menu {
    width: 255px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 25px;
    margin-bottom: 7px;
    letter-spacing: 0.000000000001em;
  }

  .moder-review-left-comment-menu-visible {
    color: #6a7b83;
    font-size: 14px;
    cursor: pointer;
  }

  .moder-review-left-about {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
  }

  .moder-review-left-about-item {
    margin: 0 5px 14px 5px;
  }

  .moder-review-left-bottom {
    width: 378px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 23px;
  }

  .moder-review-left-bottom-right {
    color: #b4bdc1;
    font-size: 1em;
    font-weight: bold;
  }

  .moder-review-left-bottom-buttons {
    width: 315px;
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5def4;
    margin-bottom: 20px;
  }

  .moder-review-left-bottom-button {
    width: 104px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .moder-review-left-bottom-buttons > div:nth-child(2) {
    border-left: 1px solid #d5def4;
    border-right: 1px solid #d5def4;
  }

  .moder-review-left-bottom-button .text {
    color: #6a7b83;
  }

  .moder-review-left-bottom-button img {
    width: 20px;
  }
}

@media (min-width: 620px) and (max-width: 1149px) {
  ::-webkit-scrollbar-button {
    background: #ffffff none no-repeat;
    width: 5px;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 7px;
    background-color: #c1c1c1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #56999f;
  }

  ::-webkit-resizer {
    background: #ffffff none no-repeat;
    width: 7px;
    height: 0;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }
}

@media (max-width: 768px) {
  .link {
    color: #3897f0;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }

  .text {
    color: black;
    font-size: 14px;
  }

  ::-webkit-scrollbar-button {
    background: #ffffff none no-repeat;
    width: 5px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 7px;
    background-color: #c1c1c1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #56999f;
  }

  ::-webkit-resizer {
    background: #ffffff none no-repeat;
    width: 7px;
    height: 0px;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  .toMob {
    width: 375px;
    font-size: 16px;
    margin-top: 30px;
    display: none;
  }

  body > div:nth-child(2) {
    margin-top: 25px;
  }

  .moder-review:not(:last-child) {
    margin-bottom: 40px;
  }

  .moder-review {
    border-radius: 15px;
    -webkit-box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.08);
    -moz-box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.08);
    box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.08);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .moder-review-who {
    position: absolute;
    font-size: 16px;
    color: #b4bdc1;
    top: 25px;
    right: 15px;
    width: 100px;
    text-align: end;
  }

  .moder-review-who span {
    display: none;
  }

  .moder-review-left {
    width: 91%;
    margin-top: 32px;
  }

  .moder-review-left-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: -8px;
  }

  .moder-review-left-top-me-pre {
    margin-bottom: 5px;
  }

  .moder-review-left-top-me-content {
    margin-bottom: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  .moder-review-left-top-me-more {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: 78px;
    right: 10px;
  }

  .moder-review-left-top-me-more-mark {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 7px;
  }

  .moder-review-left-top-me-more .text {
    font-size: 14px;
  }

  .moder-review-left-top-me-more > .text {
    top: 110px;
  }

  .moder-review-who {
    font-size: 1em;
  }

  .moder-review-left-top-me-more-mark img {
    width: 15px;
  }

  .moder-review-left-top-screens {
    width: 268px;
    top: 272px;
    display: none;
  }

  .moder-review-left-top-screens .link {
    font-size: 16px;
    margin-top: -3px;
  }

  .moder-review-left-top-screens-container {
    width: 265px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .moder-review-left-top-screens-item {
    margin-top: 10px;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5dfe4;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }

  .moder-review-left-comment-menu {
    width: 255px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .moder-review-collapse-info-left-part {
    flex-direction: column;
  }

  .moder-review-left-comment-menu-visible {
    color: #6a7b83;
    font-size: 14px;
    cursor: pointer;
  }

  .moder-review-left-about {
    display: block;
    width: 100%;
    margin-top: 20px;
  }

  .moder-review-left-about-item {
    margin-bottom: 15px;
  }

  .moder-review-left-bottom {
    width: 270px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin: 10px auto;
  }

  .moder-review-left-bottom-right {
    color: #b4bdc1;
  }

  .moder-review-left-bottom-buttons {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5def4;
  }

  .moder-review-left-bottom-button {
    width: 104px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .moder-review-left-bottom-buttons > div:nth-child(2) {
    border-left: 1px solid #d5def4;
    border-right: 1px solid #d5def4;
  }

  .moder-review-left-bottom-button .text {
    color: #6a7b83;
  }

  .moder-review-left-bottom-button img {
    width: 20px;
  }

  .moder-review-right2 {
    margin-top: 0;
  }

  .moder-review2 {
    height: 940px;
  }

  .no {
    width: 100%;
    height: 100vh;
    position: relative;
    background: white;
    box-shadow: 0 3px 10px rgba(0, 73, 129, 0.1);
    overflow: auto;
  }

  .close {
    width: 16px;
    position: absolute;
    top: 23px;
    right: 28px;
    cursor: pointer;
    display: none;
  }

  .popup-top {
    width: 100%;
    height: 60px;
    border-radius: 10px 10px 0 0px;
    border-bottom: 1px solid #d5dfe4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .popup-top-text {
    font-size: 1.7em;
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: 0;
    font-family: Rubik;
  }

  .popup-content {
    margin-top: 23px;
    margin-left: 15px;
  }

  .no-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 850px;
    overflow: auto;
  }

  .popup-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .popup-item .text {
    font-size: 2.6em;
  }

  .popup-item img {
    width: 15px;
    margin-right: 7px;
    cursor: pointer;
  }

  .popup-header {
    color: #6a7b83;
    margin-bottom: 15px;
    padding-top: 23px;
    border-top: 1px solid #d5dfe4;
    width: 105.5%;
    padding-left: 15px;
    margin-left: -15px;
  }

  .popup-header2 {
    color: #6a7b83;
    margin-top: 30px;
  }

  .popup-content > .popup-header {
    width: 100%;
  }

  .popup-bottom {
    width: 215px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: 12px;
    right: 7px;
    margin-top: 0 !important;
  }

  .popitem {
    display: none;
  }

  .popup-bottom-button {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }

  .popup-bottom > div:nth-child(1) {
    width: 95px;
    border: 1px solid #d5def4;
  }

  .popup-bottom > div:nth-child(2) {
    width: 106px;
    height: 36px;
    background: #fadb67;
  }

  .other {
    width: 100%;
    height: 585px;
    position: relative;
    background: white;
    box-shadow: 0 3px 10px rgba(0, 73, 129, 0.1);
  }

  .popup2-item-top {
    margin-bottom: 20px;
  }

  .other .popup-bottom {
    margin-top: 25px;
  }

  .moder-review-left-bottom-right {
    position: absolute;
    top: 13px;
    left: 43%;
  }

  .screens2 {
    top: 190px;
    left: 5.5%;
  }

  .comment2 {
    margin-top: 130px;
  }

  .moder-review3 {
    height: 670px;
  }

  .aboutprob {
    margin-top: 530px;
  }

  .screensprob {
    top: 550px;
  }

  .moder-reviewprob {
    height: 900px;
  }

  .popups {
    align-items: flex-start;
    height: auto;
    overflow: hidden;
  }
  .popup-bottom {
    right: 15px;
  }
  .popup-item img {
    width: 22px;
    margin-top: -3px;
  }
  .popup-content div:nth-child(2) {
    border: none;
    padding-top: 0;
  }
  .problem {
    width: 300px !important;
  }
  .popup-content div:nth-child(1) .img {
    width: 28px;
    margin-top: -1px;
  }
  .popup-item {
    width: 100%;
  }
  .popup-item .text {
    font-size: 15px !important;
  }
  .popup2-item-top .text {
    width: 55%;
  }
}

.moder-reviewbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.moder-reviewbox_label {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  padding-left: 30px;
}

.moder-reviewbox_label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 19px;
  border: 1px solid #d5dfe4;
  border-radius: 3px;
}

.moder-reviewbox:checked + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 19px;
  background-color: #3897f0;
  border: 1px solid #3897f0;
  border-radius: 3px;
}

.moder-reviewbox:checked + label::after {
  content: '';
  display: block;
  width: 6px;
  height: 11px;
  position: absolute;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  left: 7px;
  top: 2px;
  -webkit-transform: rotateZ(35deg);
  transform: rotateZ(35deg);
}

.moder-reviewbox:disabled + label {
  color: #a3a7a9;
}

.upper-part + .moder-review {
  margin-top: 0;
}

.swap-btn {
  position: absolute;
  margin-top: -22px;
}

.swap-btn svg {
  height: 20px;
  width: 20px;
}

.swap-btn svg:hover {
  fill: #3897f0;
}

@media screen and (max-width: 768px) {
  .swap-btn {
    position: static;
    margin-top: 0;
    padding: 5px;
    width: 35px;
    height: 35px;
  }

  .swap-btn svg {
    height: 25px;
    width: 25px;
  }

  .my-account-link {
    margin-bottom: 0;
  }
}

.color-red {
  color: #e17055;
}

.refuse_comment {
  line-break: anywhere;
}

.custom-star {
  width: px !important;
}
</style>
