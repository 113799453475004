<template>
  <div class="d-inline-block">
    <div
      class="mx-2"
      :style="`width: ${width}px; display: ${inp ? 'inline-block' : 'none'} !important`"
    >
      <b-form-input
        ref="sinp"
        v-if="['string', 'number'].includes(datatype)"
        :type="datatype"
        autofocus
        @blur="inp = false"
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
      ></b-form-input>
      <b-form-select
        @input="
          (e) => {
            $emit('input', e);
            inp = false;
          }
        "
        @blur="inp = false"
        ref="select"
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
        v-else-if="datatype === 'select'"
      ></b-form-select>
    </div>
    <span @click="textClick" class="d-inline-block mx-1" v-if="!inp">{{ formattedValue }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    width: {
      type: Number,
      default: 90,
    },
    datatype: {
      type: String,
      default: 'string',
    },
  },
  data: () => ({
    inp: false,
  }),
  computed: {
    formattedValue() {
      if (this.datatype === 'select') {
        const val = this.$attrs.options.find((v) => v.value === this.value);
        if (val) {
          return val.text;
        }
      }
      if (!this.value) {
        if (this.datatype === 'string') {
          return 'укажите значение';
        }
        if (this.datatype === 'number') {
          return 0;
        }
      }
      return this.value;
    },
  },
  methods: {
    textClick() {
      this.inp = true;
    },
  },
};
</script>
