<template>
  <b-modal size="lg" v-model="show" :title="title" hide-footer>
    <template v-if="reviews && reviews.length">
      <moder-review
        :key="review.id"
        :review="review"
        :collapsed="false"
        :hide-controls="true"
        v-for="review in reviews"
      />
    </template>
    <Loader v-else-if="!reviews" />
    <h1 v-else>Нет отзывов</h1>
  </b-modal>
</template>

<script>
import Loader from '@main/components/Loader.vue';
import { mapState, mapMutations } from 'vuex';
import ModerReview from './ModerReview.vue';

export default {
  components: {
    ModerReview,
    Loader,
  },
  computed: {
    ...mapState({
      reviews: (state) => state.reviewsModeration.reviewsPopupList,
      title: (state) => state.reviewsModeration.reviewsPopupTitle,
    }),
    show: {
      get() {
        return this.$store.state.reviewsModeration.reviewsPopupShow;
      },
      set() {
        this.hide();
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations('reviewsModeration', ['hideReviewsPopup']),
    hide() {
      this.hideReviewsPopup();
    },
  },
};
</script>
